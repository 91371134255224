export const myMixins = {
  methods: {
    currentChange(e) {
      // console.log(e);
      this.pageNo = e;
      this.loadData();
    },
    sizeChange(e) {
      this.pageSize = e;
      this.loadData();
    }
  },
}
