<template>
  <div class="commonTableBox">
    <el-table ref="table" :data="dataSource" border height="1">
      <el-table-column v-if="hasRowSelection" type="selection" width="55" align="center"></el-table-column>
      <el-table-column v-if="hasIndex" type="index" width="55" align="center" label="序号"></el-table-column>
      <el-table-column :label="item.title" :prop="item.key" :align="item.align || 'center'" :width="item.width || ''" v-for="(item, index) in columnsList" :key="index">
        <template slot-scope="scope">
          <template v-if="item.scopedSlots && item.scopedSlots.columnSlot">
            <slot :name="item.key" :record="scope.row"></slot>
          </template>
          <template v-else>
            {{ scope.row[item.key] }}
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="total" class="pageNation">
      <el-pagination
        @current-change="currentChange"
        @size-change="sizeChange"
        :page-sizes="pageSizes"
        :current-page="searchForm.pageNo"
        :page-size="searchForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否有选择框
    hasRowSelection: {
      type: Boolean,
      default: false
    },
    // 是否显示index
    hasIndex: {
      type: Boolean,
      default: false
    },
    columnsList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 分页大小
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 15, 20, 30]
      }
    },
    total: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      searchForm: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  mounted() {
    // console.log(this.maxHeight);
  },
  methods: {
    currentChange(e) {
      // console.log(e);
      this.$emit('currentChange',e)
    },
    sizeChange(e) {
      // console.log(e);
      this.$emit('sizeChange', e)
    }
  }
}
</script>

<style lang="less" scoped>
  .commonTableBox{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  .pageNation {
    text-align: center;
    margin: 10px 0;
    height: 30px;
  }
</style>