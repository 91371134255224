<template>
  <div class="wrap">
    <div class="box-top">
      <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="addUser"
      >新增
      </el-button>
      <!-- <el-input v-model="mess" placeholder="账号" clearable></el-input> -->
      <el-input
          v-model="mess"
          placeholder="请输入账号/单位查询"
          @keydown.enter.native="search"
          clearable
      >
        <i
            slot="prefix"
            class="el-input__icon el-icon-search"
            @click="search"
        ></i>
      </el-input>
      <!-- <el-button type="primary" icon="el-icon-search" size="small" @click="search" style="margin-left:10px;">查询</el-button> -->
    </div>
    <!-- 列表 -->
    <common-table ref="commonTable" :dataSource="tableData" :columnsList="columns" :total="total"
                  @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="roleName" slot-scope="{record}">
        {{ roleMessage[record.roleName] }}
      </template>
      <template slot="action" slot-scope="{record}">
        <el-button @click="resetPws(record)" type="text" style="color: #b51c1b;">重置密码</el-button>
        <el-divider direction="vertical" v-if="role=='admin' && record.roleName != 'admin'"></el-divider>
        <el-popconfirm title="确定移除吗？" @confirm="confirm(record)">
          <el-button slot="reference" v-if="role=='admin' && record.roleName != 'admin'" type="text"
                     style="color: #b51c1b;">移除
          </el-button>
        </el-popconfirm>
        <el-divider direction="vertical" v-if="role=='admin' && record.username !== username"></el-divider>
        <el-button @click="handleSetAdmin(record)" v-if="role=='admin' && record.username !== username" type="text"
                   style="color: #b51c1b;">置为管理员
        </el-button>
      </template>
    </common-table>
    <!-- <div style="flex:1;">
      <el-table
        :data="tableData"
        border
        ref="table"
        height="100%"
        style="width: 100%;"
      >
        <el-table-column
          prop="username"
          label="账号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="nickname"
          label="用户昵称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="单位"
          align="center"
        >
          <template slot-scope="scope">{{scope.row.company?scope.row.company:scope.row.school}}</template>
        </el-table-column>
        <el-table-column
          prop="roleName"
          label="账号类型"
          align="center"
        >
          <template slot-scope="scope">
            {{roleMessage[scope.row.roleName]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="注册时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="options"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
             <el-button @click="editButton(scope.row)" type="text" style="color: #00788b;">编辑</el-button>
            <el-button
              @click="resetPws(scope.row)"
              type="text"
              style="color: #b51c1b;"
            >重置密码</el-button>
            <el-divider
              direction="vertical"
              v-if="role=='admin'"
            ></el-divider>
            <el-button
              @click="del(scope.row)"
              type="text"
              style="color: #b51c1b;"
            >删除</el-button>
            <el-button
              @click="remove(scope.row)"
              v-if="role=='admin'"
              type="text"
              style="color: #b51c1b;"
            >移除</el-button>
            <el-divider
              direction="vertical"
              v-if="role=='admin' && scope.row.username !== username"
            ></el-divider>
            <el-button
              @click="handleSetAdmin(scope.row)"
              v-if="role=='admin' && scope.row.username !== username"
              type="text"
              style="color: #b51c1b;"
            >置为管理员</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div> -->

    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="height:32px;"
    >
    </el-pagination> -->

    <!-- 弹窗 -->
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
        center
        :close-on-press-escape="false"
        :close-on-click-modal="false"
    >
      <user-edit
          v-if="dialogVisible"
          ref="userEdit"
          :oldForm="form"
      ></user-edit>
      <span
          slot="footer"
          class="dialog-footer"
      >
        <el-button
            @click="dialogVisible = false"
            :size="btnSize"
        >取 消</el-button>
        <el-button
            type="primary"
            @click="submit()"
            :size="btnSize"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userList,
  addUser,
  updateUser,
  deleteUser,
  changeCompanyAdmin,
} from "../../api/userManage";
import commonTable from "../../components/commonTable.vue";
import userEdit from "./userEdit.vue";
import {myMixins} from "../../mixins/myMixins";

export default {
  name: "userManage",
  mixins: [myMixins],
  inject: ["loginOut"],
  data() {
    return {
      id: JSON.parse(sessionStorage.getItem("userInfo")).id || "",
      title: "新增用户",
      mess: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {},
      currentPage4: 1,
      dialogVisible: false,
      btnSize: "mini",
      tableData: [],
      role: JSON.parse(sessionStorage.getItem("roleInfo")).roleName,
      username: JSON.parse(sessionStorage.getItem("userInfo")).username,
      roleMessage: {
        root: "超级管理员",
        admin: "管理员",
        guest: "普通成员",
        temporary: "临时用户",
      },
      columns: [
        {
          title: '账号',
          key: 'username',
          align: 'center'
        },
        {
          title: '用户昵称',
          key: 'nickname',
          align: 'center'
        },
        {
          title: '单位',
          key: 'company',
          align: 'center'
        },
        {
          title: '账号类型',
          key: 'roleName',
          align: 'center',
          scopedSlots: {
            columnSlot: 'roleName'
          }
        },
        {
          title: '注册时间',
          key: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          scopedSlots: {
            columnSlot: 'action'
          }
        }
      ]
    };
  },
  components: {
    userEdit,
    commonTable
  },
  mounted() {
    this.loadData();
  },
  methods: {
    search() {
      this.pageNo = 1;
      this.loadData();
    },
    loadData() {
      let data = {
        search: this.mess,
        id: this.id,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      userList(data).then((res) => {
        if (res.success) {
          this.tableData = res.result.data;
          this.total = res.result.count;
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          });
        }
      });
    },
    addUser() {
      this.form = {};
      this.dialogVisible = true;
      this.title = "新增用户";
    },
    submit() {
      this.$refs.userEdit.$refs.form.validate((valid) => {
        let formInfo = this.$refs.userEdit.form;
        formInfo.nickname = "昵称";
        formInfo.password = "123456";
        if (!valid) {
          return;
        } else {
          if (this.title == "新增用户") {
            formInfo = JSON.parse(JSON.stringify(formInfo));
            let role = JSON.parse(sessionStorage.getItem("roleInfo")).roleName;
            if (role == "root") {
              formInfo.role = "admin";
            } else if (role == "admin") {
              formInfo.role = "guest";
            }
            addUser(formInfo).then((res) => {
              if (res.success) {
                this.$message.success(res.message);
                this.dialogVisible = false;
                this.loadData();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            let params = {};
            params.id = formInfo.id;
            params.nickname = formInfo.nickname;
            params.password = formInfo.password;
            params.company = formInfo.company;
            params.role = formInfo.role;
            updateUser(params).then((res) => {
              if (res.success) {
                this.$message.success(res.result);
                this.dialogVisible = false;
                this.loadData();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    editButton(e) {
      this.form = e;
      this.dialogVisible = true;
      this.title = "编辑用户";
    },
    resetPws(record) {
      updateUser({id: record.id}).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // del(e) {
    //   deleteUser({ id: e.id }).then((res) => {
    //     if (res.success) {
    //       this.$message.success(res.message);
    //       this.loadData();
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    remove(record) {
      deleteUser({id: record.id}).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.loadData();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleSetAdmin(record) {
      changeCompanyAdmin({
        username1: this.username,
        username2: record.username,
      }).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.loginOut();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // handleSizeChange(val) {
    //   this.pageSize = val;
    //   this.loadData();
    // },
    // handleCurrentChange(val) {
    //   this.pageNo = val;
    //   this.loadData();
    // },
    confirm(e) {
      this.remove(e);
    }
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/commonMenu.less";
</style>
