<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <!-- <el-form-item label="昵称：" prop="nickname">
        <el-input v-model="form.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="初始密码：" prop="password">
        <el-input v-model="form.password" placeholder="请输入密码"></el-input>
      </el-form-item> -->
      <el-form-item label="注册账号：" prop="phone">
        <el-input v-model.number="form.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item v-if="show" label="单位：" prop="company">
        <el-input v-model="form.company" placeholder="请输入单位"></el-input>
      </el-form-item>
      <el-form-item v-if="show" label="单位类型：" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">企业</el-radio>
          <!-- <el-radio :label="2">学校</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <p>！注意：未注册的账号，默认初始密码为 123456</p>
      <!-- <el-form-item label="用户级别：" prop="role">
        <el-select v-model="form.role" placeholder="请选择用户级别">
          <el-option label="系统管理员" value="root"></el-option>
          <el-option label="单位管理员" value="admin"></el-option>
          <el-option label="成员账号" value="guest"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
import { getUser } from '../../api/userManage'
export default {
  name: 'userEdit',
  props: ['oldForm'],
  data() {
    var checkPhone = (rule, value,callback) => {
      if(!value) {
        return callback(new Error('手机号不能为空'));
      }
      let reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
      if(reg.test(value)){
        callback()
      }else{
        return callback(new Error('请输入正确的手机号'));
      }
    };
    return {
      rules: {
        nickname: [
            { required: true, message: '请输入匿称', trigger: 'blur' }],
        password: [{ required: true, message: '请正确输入密码', trigger: 'blur' }],
        phone: [{ validator: checkPhone, required: true, trigger: 'blur' }],
        company: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
        role: [{ required: true, message: '请选择用户级别', trigger: 'blur' }],
        type:[{ required: true, message:'请选择单位类型', trigger: 'blur' }]
      },
      form: {
        // nickname: '',
        password: '',
        phone: '',
        company: '',
        role: '',
        type:null,
      },
      role:'',
    }
  },
  computed: {
    show() {
      let flag = false;
      if(this.form.id) {
        if(this.role) {
          flag = false;
        }
      }else {
        if(this.role == 'root') {
          flag = true;
        }
      }
      return flag;
    }
  },
  mounted() {
    this.role = JSON.parse(sessionStorage.getItem('roleInfo')).roleName;
    if(this.role == 'root') {
      this.form.type = 1;
    }
    if(this.oldForm.id) {
      this.form.id = this.oldForm.id;
      this.form.nickname = this.oldForm.nickname;
      this.form.password = this.oldForm.password;
      this.form.phone = this.oldForm.username;
      this.form.company = this.oldForm.company;
      this.form.role = this.oldForm.role;
      this.getUserMessage();
    }
  },
  methods: {
    getUserMessage() {
      getUser({id: this.form.id}).then(res => {
        if(res.success) {
          this.form.role = res.result.role;
        }else {
          this.$message.error(res.message);
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
  .el-select{
    width: 100%;
  }
  p{
    text-align: center;
    color: red;
  }
</style>