import { getAction,postAction } from "../utils/request"

export function userList(data) {
  return getAction('/web/user/listUsers',data)
}

export function addUser(data) {
  return postAction('/web/user/addUser',data)
}

export function updateUser(data) {
  return getAction('/web/user/updateUser',data)
}

export function deleteUser(data) {
  return getAction('/web/user/deleteUser',data)
}

export function getUser(data) {
  return getAction('/web/user/getUser',data)
}

export function changeCompanyAdmin(data) {
  return getAction('/web/user/changeCompanyAdmin',data)
}